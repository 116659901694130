import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { User } from "../models/user.model";
import { Vendorbranch } from "../models/vendorbranch.model";
import * as moment from "moment";
import { Observable, throwError, BehaviorSubject } from "rxjs";

import { tap, map, retry, catchError } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Queue } from "../models/queue.model";
import { VendorPackage } from "../models/vendorpackage.model";
import { Vendor } from "../models/vendor.model";
import { Package } from "../models/package.model";
import { Pushnotification } from "../models/pushnotification.model";

@Injectable({
  providedIn: "root"
})
export class VendorService {
  baseUrl = environment.apiserver;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };

  constructor(private httpClient: HttpClient) {}

  getVendors(value?: string): Observable<Vendor[]> {
    let url = `${this.baseUrl}/vendor/getall?query=` + value;

    return this.httpClient
      .get<Vendor[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getVendorByName(value: string): Observable<Vendorbranch[]> {
    let url = `${this.baseUrl}/vendor/search?query=` + value;

    return this.httpClient
      .get<Vendorbranch[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getNextNum(id: number): Observable<string> {
    let url = `${this.baseUrl}/vendor/nextno/` + id;

    return this.httpClient
      .get<string>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  reserveNumber(data: any): Observable<string> {
    let url = `${this.baseUrl}/vendor/reserve`;

    return this.httpClient
      .post<string>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  checkReservedNumber(data: any): Observable<string> {
    let url = `${this.baseUrl}/queue/checkreserve`;

    return this.httpClient
      .post<string>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllReservations(id: number): Observable<Queue> {
    let url = `${this.baseUrl}/queue/allreservations/` + id;

    return this.httpClient
      .get<Queue>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllBranchReservations(id: number): Observable<Queue> {
    let url = `${this.baseUrl}/queue/allbranchreservationscount/` + id;

    return this.httpClient
      .get<Queue>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getNotifications(id: number): Observable<Pushnotification> {
    let url = `${this.baseUrl}/notifications/` + id;

    return this.httpClient
      .get<Pushnotification>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateNotifications(id: number): Observable<string> {
    let url = `${this.baseUrl}/notifications/update/` + id;

    return this.httpClient
      .post<string>(url, JSON.stringify(id), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  checkNotifications(id: number): Observable<Pushnotification[]> {
    let url = `${this.baseUrl}/notifications/check/delivery/` + id;

    return this.httpClient
      .get<Pushnotification[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateNotificationDelivery(id: number, val: number): Observable<string> {

    let url = `${this.baseUrl}/notifications/delivered/`+ id +`/`+ val;

    return this.httpClient.post<string>(url, JSON.stringify(id), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllMissedCustomers(id: number): Observable<Queue> {
    let url = `${this.baseUrl}/queue/allmissedcustomerscount/` + id;

    return this.httpClient
      .get<Queue>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateQueueStatus(data: any): Observable<string> {
    let url = `${this.baseUrl}/queue/update`;

    return this.httpClient
      .post<string>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getNextActiveNo(id: number): Observable<Queue> {
    let url = `${this.baseUrl}/vendor/activeno/` + id;

    return this.httpClient
      .get<Queue>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveBranchPicture(data) {
    let url = `${this.baseUrl}` + "/vendorbranch/updatepropic";
    debugger;

    return this.httpClient
      .post(url, data)
      .pipe(retry(0), catchError(this.handleError));
  }

  getQueueByVendor(id): Observable<Queue[]> {
    let url = `${this.baseUrl}/queue/getall/` + id;

    return this.httpClient
      .get<Queue[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getQueueByNo(qno, vendor): Observable<Queue> {
    let url = `${this.baseUrl}/queue/getByNo/` + vendor + `?q=` + qno;

    return this.httpClient
      .get<Queue>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getVendorPackage(id: number): Observable<VendorPackage> {
    let url = `${this.baseUrl}/vendor/package/` + id;

    return this.httpClient
      .get<VendorPackage>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getPackage(id: number): Observable<VendorPackage> {
    let url = `${this.baseUrl}/vendor/package/drop/` + id;

    return this.httpClient
      .get<VendorPackage>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getVendor(): Observable<any[]> {
    let url = `${this.baseUrl}/vendorname`;

    return this.httpClient
      .get<any>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getUsersByBranch(id): Observable<User[]> {
    let url = `${this.baseUrl}/user/bybranch/` + id;

    return this.httpClient
      .get<User[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getVendorById(id): Observable<Vendor> {
    let url = `${this.baseUrl}/vendor/byid/` + id;

    return this.httpClient
      .get<Vendor>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  updaterole(data: any): Observable<any> {
    let url = `${this.baseUrl}/updateRole`;

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllPackages(): Observable<Package[]> {
    let url = `${this.baseUrl}/vendor/allpackages`;

    return this.httpClient
      .get<Package[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getUser(id: number): Observable<any> {
    let url = `${this.baseUrl}/user/` + id;

    return this.httpClient
      .get<any>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  addPackage(data: any): Observable<any> {
    let url = `${this.baseUrl}/package/insert`;

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveVendor(data: any): Observable<Vendor> {
    let url = `${this.baseUrl}/vendor/save`;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/vendor/update/` + data["id"];
    }

    return this.httpClient
      .post<Vendor>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getVendorBranch(id?: number, page?: number): Observable<Vendorbranch[]> {
    let url = `${this.baseUrl}/vendor/branch/` + id + `?page=1`;

    if (page > 0) {
      url = `${this.baseUrl}/vendor/branch/` + id + `?page=` + page;
    }

    return this.httpClient
      .get<Vendorbranch[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getVendorBranchById(id?: number, page?: number): Observable<Vendorbranch[]> {
    let url = `${this.baseUrl}/vendorbranch/` + id;

    if (page > 0) {
      url = `${this.baseUrl}/vendorbranch/` + id + `?page=` + page;
    }

    return this.httpClient
      .get<Vendorbranch[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveVendorBranch(data: any): Observable<Vendorbranch> {
    let url = `${this.baseUrl}/vendorbranch/save`;
    debugger;

    if (data["id"] > 0) {
      url = `${this.baseUrl}/vendorbranch/update/` + data["id"];
    }

    return this.httpClient
      .post<Vendorbranch>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  cancelQueueReservation(data: any): Observable<string> {
    let url = `${this.baseUrl}/queue/cancelreservation`;

    return this.httpClient
      .post<string>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveUser(data: any): Observable<User> {
    let url = `${this.baseUrl}/user/update`;
    if (data["id"] > 0) {
      url = `${this.baseUrl}/user/update/` + data["id"];
    }

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/customer/update`;

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateVendorToUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/vendor/user/update`;

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveVendorUser(data: any): Observable<User> {
    let url = `${this.baseUrl}/vendorsave`;

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getNotificationKeys(): Observable<any> {

    let url = `${this.baseUrl}/sendNotificationKeys`;

    return this.httpClient.get<any>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // sendPushNotification(data: any){
  //   let url = `https://onesignal.com/api/v1/notifications`;


  //   return this.httpClient
  //     .post(url, JSON.stringify(data), {
  //       headers: new HttpHeaders({
  //           'Authorization': 'Basic ' + 'NDdlNjE0OGQtOGYyZC00MTczLWE3OTAtYTFkYWU0YjI2YWVh',
  //           'Content-Type': 'application/json'
  //       })
  //     })
  //     .pipe(retry(1), catchError(this.handleError));
  // }

  // saveNotification(data: any): Observable<Pushnotification> {

  //   let url = `${this.baseUrl}/queue/insertnotification`; debugger;

  //   if (data['id'] >0) {
  //     url = `${this.baseUrl}/vendor/update/` + data['id'];
  //   }

  //   return this.httpClient.post<Pushnotification>(url, JSON.stringify(data), this.httpOptions)
  //     .pipe(
  //       retry(1),
  //       catchError(this.handleError)
  //     )
  // }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error;
    } else {
      // Get server-side error
      errorMessage = error.error;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
