import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/models/user.model';
import { VendorService } from 'src/app/shared/services/vendor.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() logged : boolean = false;
  constructor(private _router: Router,private authService: AuthService, private vendorService :  VendorService) { }
  session: User;
  
  imagepath : string = environment.imageserver ;
  baseurl = environment.baseurl;
  notificationCount : number = 0;
  displayNotification : boolean = false;
  notificationList : any;
  hasNotification : boolean;
  readNotifications : any;
  

  ngOnInit() {
    //getting current user
    this.authService.user.subscribe(data => {
      this.session = data;
      // console.log(data);
      if(this.session.name == undefined){
        this.logged = false;
      }else if(this.session.name != ""){
        
        this.logged = true;
      }
      
    });

    this.getNotificationList(this.session.id)
  }

  getNotificationList(id){
    this.notificationCount = 0;
    this.vendorService.getNotifications(id).subscribe(res => {
      this.notificationList = res;
      for (let nfl of this.notificationList) {
        if(nfl.read==0){
          console.log(nfl.read);
          this.notificationCount = this.notificationCount+1;
        }
      }
      this.hasNotification = true;
    }, error =>{
      this.notificationList = "You have no notifications"
      this.hasNotification = false;
    });
  }

  markNotificationsRead(id){
    this.vendorService.updateNotifications(id).subscribe(res => {
      this.readNotifications = res;
      this.notificationCount = 0;
    }, error =>{
      this.readNotifications = "You have no unread notifications"
    });
  }


  showNotifications(){
    this.displayNotification = true;
    this.getNotificationList(this.session.id);
    this.markNotificationsRead(this.session.id);
    // console.log(this.notificationList);
  }

  cancelNotification() {
    this.displayNotification = false;
  }

  // goToLogin(){    
  //   this._router.navigate(['/login']);
  // }
  
  // logout(){
  //   this.authService.updatedDataSelection(new User());

  //   this.authService.user.subscribe(data => { debugger;
  //   this.userSession = data;
  //   this.logged = false;
  //   this._router.navigate(['/login']);
  // });

  //}

}
