import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { User } from "../models/user.model";
import * as moment from "moment";
import { Observable, throwError, BehaviorSubject } from "rxjs";

import { tap, map, retry, catchError } from "rxjs/operators";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Vendorbranch } from "../models/vendorbranch.model";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  getUser(id: any, page: any) {
    throw new Error("Method not implemented.");
  }

  baseUrl = environment.apiserver;
  userSession: User;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json"
    })
  };
  constructor(private httpClient: HttpClient) {
    //getting current user
    this.user.subscribe(data => {
      this.userSession = data;
    });
  }

  private session = new BehaviorSubject(new User());
  user: Observable<User> = this.session.asObservable();

  private session2 = new BehaviorSubject(new Vendorbranch());
  vendor: Observable<Vendorbranch> = this.session2.asObservable();

  private session3 = new BehaviorSubject(new Vendorbranch());
  vendorbranch: Observable<Vendorbranch> = this.session3.asObservable();

  updatedDataSelection(data: User) {
    this.session.next(data);
  }

  updatedVendorDataSelection(data: Vendorbranch) {
    this.session2.next(data);
  }

  updatedVendorBranchDataSelection(data: Vendorbranch) {
    this.session3.next(data);
  }

  logout(): Observable<string> {
    let url = `${this.baseUrl}/logout`;

    return this.httpClient
      .get<string>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMemberByUser(id?: number, page?: number): Observable<User> {
    let url = `${this.baseUrl}/user?page=1`;

    if (id > 0) {
      url = `${this.baseUrl}/user/` + id;
    }

    return this.httpClient
      .get<User>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  validateUser(data: any): Observable<any> {
    let url = `${this.baseUrl}/login`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  setNewUserPassword(data: any): Observable<any> {
    let url = `${this.baseUrl}/user/setNewPwd`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  resetPw(data: any): Observable<any> {
    let url = `${this.baseUrl}/resetpw`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendPasswordResetLink(data: any) {
    let url = `${this.baseUrl}/sendPasswordResetLink`;

    return this.httpClient
      .post(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  public isLoggedIn() {
    var yes = moment().isBefore(this.getExpiration());
    return yes;
  }

  getExpiration() {
    // this.user.subscribe(data =>{
    //   const expiration = data.tokenexpire;
    //   //const expiresAt = JSON.parse(expiration);
    //   return (expiration);
    // });

    const expiration = this.userSession.tokenexpire;
    return expiration;
  }

  getLoggedUser(): Observable<boolean> {
    let url = `${this.baseUrl}/loggeduser`;
    var data = {};

    this.user.subscribe(res => {
      data = {
        token: res.token
      };
    });

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  endSession(): Observable<boolean> {
    let url = `${this.baseUrl}/logout`;

    var data = {};

    this.user.subscribe(res => {
      data = {
        token: res.token
      };
    });

    return this.httpClient
      .post<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  saveUser(data: any): Observable<User> {
    let url = `${this.baseUrl}/register`;

    if (data["id"] != 0) {
      url = `${this.baseUrl}/update/` + data["id"];
    }

    return this.httpClient
      .post<User>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = error.error;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
