import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from "ngx-spinner";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
//import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
//import { LoginComponent } from './views/login/login.component';
//import { RegisterComponent } from './views/register/register.component';

//crm

import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';

const APP_CONTAINERS = [
  //DefaultLayoutComponent
];



// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
//import { ChartsModule } from 'ng2-charts';
import { LoginModule } from './modules/login/login.module';
import { AuthInterceptor } from './shared/core/AuthInterceptor';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
// import { LearnAboutComponent } from './modules/learn-about/learn-about.component';
// import { NumericDirective } from './numeric.directive';
//import { RegisterModule } from './views/register/register.module';

//import { AuthInterceptor } from './shared/core/AuthInterceptor';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule, 
    ReactiveFormsModule,
    AppRoutingModule,
   
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    //ChartsModule,
    HttpClientModule,
    LoginModule,

    NgxSpinnerModule,

    MessagesModule,
    MessageModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    // LearnAboutComponent,
    // NumericDirective,
    
    //RegisterComponent,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  
  bootstrap: [ AppComponent ],
  entryComponents: []

})
export class AppModule { }
