import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
//import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
//import { AccountregisterComponent } from './modules/accountregister/accountregister.component';
import { AccountRegisterComponent } from './modules/account-register/account-register.component';
import { AuthGuard } from './shared/core/auth-guard.service';
// import { LoginComponent } from './views/login/login.component';
//import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path :'base',
    loadChildren : './modules/base/base.module#BaseModule'
  },
  {
    path :'login',
    loadChildren : './modules/login/login.module#LoginModule'
  },
  {
    path :'accountreg',
    loadChildren : './modules/accountreg/accountreg.module#AccountregModule',
    canActivate: [AuthGuard]
  },
  {
    path :'pricing',
    loadChildren : './modules/accountreg/accountreg.module#AccountregModule'
  },
  {
    path :'privacy-policy',
    loadChildren : './modules/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  },
  {
    path :'quickqueue',
    loadChildren : './modules/learnqq/learnqq.module#LearnqqModule'
  },
  {
    path :'',
    loadChildren : './modules/index/index.module#IndexModule'
  },
  {
    path :'forgotpassword',
    loadChildren : './modules/forgotpassword/forgotpassword.module#ForgotpasswordModule'
  },
  {
    path :'resetpassword',
    loadChildren : './modules/resetpassword/resetpassword.module#ResetpasswordModule'
  },
  {
    path :'permission',
    loadChildren : './modules/permission/permission.module#PermissionModule'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'accountregister',
    loadChildren : './modules/account-register/account-register.module#AccountRegisterModule',
    data: {
      title: 'Register Page'
    },
  },
  {
    path :'adminlogin',
    loadChildren : './modules/admin-login/admin-login.module#AdminLoginModule'
  },
  {
    path : 'admin',
    loadChildren : './modules/admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  }, 
  {
    path : 'vendorpackege/:id',
    loadChildren : './modules/vendor-packege/vendor-packege.module#VendorPackegeModule',
    canActivate: [AuthGuard]
  }, 
  { path: '**', component: P404Component }
];


@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
