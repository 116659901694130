import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpModule,
    MessagesModule,
    MessageModule,
    NgxSpinnerModule,
    HeaderModule,
    FooterModule
  ],
  declarations: [LoginComponent]
})
export class LoginModule { }
