// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  version : 'Version: 1.3.0.0',

  //baseurl : 'http://localhost:4200/',
  //baseurl : 'https://unicoan.com/qms/',
  baseurl : 'http://quickqueueshop.com/',
  //baseurl: 'http://staging.quickqueueshop.com/',


  //imageserver : 'http://localhost:4200/',
  //imageserver : 'https://unicoan.com/qms/',
  imageserver: 'https://unicoan.com/qms_backend/public/storage/',
  //imageserver: 'https://unicoan.com/staging_qms_backend/public/storage/',
  // imageserver : 'http://localhost:8000/storage/',

  
  //apiserver : 'http://localhost:8000/api'
  apiserver : 'https://unicoan.com/qms_backend/public/api',
  //apiserver: 'https://unicoan.com/staging_qms_backend/public/api'
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
