import * as moment from 'moment';

export class User{
    public id : number
    public name : string
    public email : string
    public password? : string
    public image? : string
    public role?: string
    public nic?: string
    public contact? : string
    public token? : string
    public tokenexpire? : moment.Moment
    public branch_id? : number
}