import { Vendor } from "./vendor.model"

export class Vendorbranch {
    public id:number
    public vendor_id: string
    public branch_name : string
    public address : string
    public contact : number
    public status : number
    public email : string
    public city : string
    public village : string
    public province : string
    public country : string
    public description : string
    public image : string
    public duration : number
    public vendor? : Vendor
}
