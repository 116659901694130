import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { enableProdMode } from '@angular/core';
import { MessageService, Message } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from 'rxjs';
import { VendorService } from 'src/app/shared/services/vendor.service';

//enableProdMode();


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  vlogin: FormGroup;
  clogin: FormGroup;
  logged : boolean = false;

  isWaiting: boolean = false;
  userSession: User;

  cloginmsgs: Message[] = [];
  vloginmsgs: Message[] = [];
  logoutmsgs: Message[] = [];

  notificationKeys: any;

  submitted: boolean;
  meta: any;
  userList: User[] = [];

  constructor(
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private _router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private vendorService: VendorService
    //private storage: Storage,
    ) { }

  ngOnInit() {

    this.logout();
    this.isWaiting = false;
    this.cloginmsgs = [];
    this.vloginmsgs = [];
    this.logoutmsgs = [];
    this.buildForm();

    //getting current user
    // this.authService.user.subscribe(data => {
    //   this.userSession = data;
    // });

  }

  logout(){    
    this.authService.logout().subscribe(res=>{
      this.logoutmsgs.push({severity:'success', detail:'Succesfully Logout!'});
      this.authService.updatedDataSelection(new User());
      this.logged = false;
    });
  }

  validateCustomer() {
    //this.isWaiting = true;
    debugger;
    if (this.clogin.invalid) {
      this.cloginmsgs = [];
      this.cloginmsgs.push({severity:'error', detail:'Please fill required fields!'});
      return true;
    }

    this.spinner.show();

    this.authService.validateUser(this.clogin.value).subscribe(res => {

      this.isWaiting = false;
      if(res['role']=="2"){

        // update user session
      this.authService.updatedDataSelection({
        id:res["id"], 
        name:res["name"], 
        email:res["email"], 
        nic:res["nic"],
        password:"", 
        image:res["image"], 
        role:res["role"],
        contact:res["contact"], 
        token :res["access_token"], 
        tokenexpire: moment().add(JSON.parse(res["expires_in"]), 'second'),
        branch_id:res["branch_id"]  
      });

      this._router.navigate(['/base/vendors']);
      this.spinner.hide();
      }else{
        this.cloginmsgs = [];
        this.cloginmsgs.push({severity:'error', detail:'Sorry, username or password is incorrect!'});
        this.spinner.hide();
      }
      

    }, err => {
      this.spinner.hide();
      this.isWaiting = false;
      this.cloginmsgs = [];
      this.cloginmsgs.push({severity:'error', detail:'Sorry, username or password is incorrect!'});
    });

  }

  validateVendor() {
    //this.isWaiting = true;
    
    if (this.vlogin.invalid) {
      this.vloginmsgs = [];
      this.vloginmsgs.push({severity:'error', detail:'Please fill required fields!'});
      return true;
    }

    this.spinner.show();

    this.authService.validateUser(this.vlogin.value).subscribe(res => {

      this.isWaiting = false;

      if(res['role']=="0"){
        this.vloginmsgs = [];
        this.vloginmsgs.push({severity:'error', detail:'Sorry, your account is yet to be activated!'});
        this.spinner.hide();
      }else if(res['role']=="1"){

         // update user session
        this.authService.updatedDataSelection({
          id:res["id"], 
          name:res["name"], 
          email:res["email"], 
          nic:res["nic"],
          password:"", 
          image:res["image"], 
          role:res["role"],
          contact:res["contact"], 
          token :res["access_token"], 
          tokenexpire: moment().add(JSON.parse(res["expires_in"]), 'second'),
          branch_id:res["branch_id"]  
        });

        this.getPushNotificationKeys();

        this._router.navigate(['/base/queue-status']);
        this.spinner.hide();
      }else{
        this.vloginmsgs = [];
        this.vloginmsgs.push({severity:'error', detail:'Sorry, username or password is incorrect!'});
        this.spinner.hide();
      }
      
    }, err => {
      this.spinner.hide();
      this.isWaiting = false;
      this.vloginmsgs = [];
      this.vloginmsgs.push({severity:'error', detail:'Sorry, username or password is incorrect!'});
    });

  } 


  buildForm() {
    //form group for register new custormer
    this.vlogin = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.clogin = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // SubmitVlogin(value: string) {

  //   if (this.vlogin.invalid) {
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill required fields!' });
  //     return true;
  //   }


  //   this.authService.validateUser(this.vlogin.value).subscribe(res => {
  //     var tempId = res['data']['id'];
  //     this.getUser();
  //     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'successfully!' });

  //     this._router.navigate(['/base/' + res['data']['id']]);


  //   });
  //   this.submitted = true;

  // }

  getUser(id?, page?) {
    // this.authService.getUser(id , page).subscribe(res =>{
    //   this.userList = res['data'];
    //   this.meta = res['meta'];
    // });
  }

  getPushNotificationKeys(){
    this.vendorService.getNotificationKeys().subscribe(res=>{
      this.notificationKeys = res;

      //this.storage.set('signalKey', res['restAPI']);

      console.log(res);
      //console.log(this.notificationKeys);

    }, err =>{
      // this.pushNotificationErrorAlert(err);
      console.log(err);
    })
  }

  // async pushNotificationErrorAlert(msg: any) {
  //   let alert = await this.alertCtrl.create({
  //       header: 'Push Notification Error!',
  //       message: 'Failed to subscribe for push notifications',
  //       buttons: ['Dismiss']
  //   });
  //   await alert.present();
  // }


}
